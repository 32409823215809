import React from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const Header = () => {
  const customStyle = {
    position: "sticky",
    top: 0,
    zIndex: 1000,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    background: "black", 
  };

  return (
    <>
   
      <div className="container-fluid" style={customStyle}>
        <Container fluid className="mob-res">
          <Navbar expand="lg" variant="dark" className="py-2">
            <Navbar.Brand href="/" className="logo-mob">
              <img src="logo.png" className="me-3" style={{ width: "148px",height:'60px' }} alt="Logo" />
              <span
                className="fw-bold fs-1"
                style={{ color: "white", fontFamily: "italic" }}
              ></span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav className="ms-auto">
                <NavLink to="/" className="nav-link text-white">
                  Home
                </NavLink>
                <NavLink to="/About" className="nav-link text-white">
                  About Us
                </NavLink>
                <NavLink to="/services" className="nav-link text-white">
                  Services
                </NavLink>
                
                <NavLink to="/gallery" className="nav-link text-white">
                  Products
                </NavLink>
                <NavLink to="/contact" className="nav-link me-2 text-white">
                  Contact Us
                </NavLink>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </div>
    </>
  );
};

export default Header;
