import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import image1 from '../../src/Assets/contact2.jpg';


export const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ymb0zin",
        "template_mlfxyxf",
        form.current,
        "WQ1EiyuosIfZS_a54",
        toast.success("Email sent successfully!")
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <div className="Contactall">
        <div className="sol11">
          <div data-aos="fade-right" className="leftsol1">
            <h1>Want To Connect with Us?</h1>

            <br />
            <br />
            <div className="r12">
              <div className="r121">
                <img src="contact.jpg" alt="Contact Icon" />
                <h4>Contact us</h4>
                <h6>+91 9036616910</h6>
              </div>

              <div className="r122">
                <img src="email.jpg" alt="Email Icon" />
                <h4>Email</h4>
                <h6>info@universalfiresecurity.com</h6>
              </div>

              <div className="r123">
                <img src="address.jpg" alt="Address Icon" />
                <h4>Address</h4>
                <h6 >
                  #10/14, 11th main road pranav 
                  complex,1st floor,no.104,next2pai 
                  showroom, 1st Stage, Gokula 1st
                   Stage, Mathikere, Bengaluru,
                   Karnataka 560054
                </h6>
              </div>
            </div>
          </div>

          <div data-aos="fade-left" className="right1">
            <img
           
              src={image1}
               alt="Image"
              className="img-fluid" // Make the image responsive
            />
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="sp"></div>
      <div className="r">
        <div className="row">
          <div
            className="col-lg-6 order-2 order-lg-1"
            style={{ padding: 90, paddingLeft: 100 }}
          >
            <div className="ress">
              <iframe
                src="https://maps.google.com/maps?q=universal%20fire%20security%20mathikhere&t=&z=13&ie=UTF8&iwloc=&output=embed"
                style={{
                  width: "600px",
                  height: "400px",
                  marginLeft: "-50px",
                  borderRadius: "20px",
                  marginTop: "-50px",
                }}
              ></iframe>
            </div>
          </div>

          <div className="col-lg-6 order-1 order-lg-2" style={{ paddingLeft: 50 }}>
            <div className="contact-form-container rounded-lg shadow-md p-3 ">
              <h1>Send message</h1>

              <form ref={form} onSubmit={sendEmail}>
                <div className="form-group">
                  <label>Name</label>
                  <input
                    className="form-control  "
                    type="text"
                    name="user_name"
                    required
                  />
                </div>
                <div style={{ padding: "5px" }}></div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    className="form-control form-control-sm"
                    type="email"
                    name="user_email"
                    required
                  />
                </div>
                <div style={{ padding: "5px" }}></div>
                <div className="form-group">
                  <label>Message</label>
                  <textarea
                    className="form-control form-control-sm"
                    name="message"
                    required
                  />
                </div>
                <div style={{ padding: "10px" }}></div>
                <div className="col-12 pt-3">
                  <input
                    className="btn btn-primary "
                    style={{ width: "230px" }}
                    type="submit"
                    value="Send"
                  />
                  <ToastContainer />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="res1">
        <iframe src="https://maps.google.com/maps?q=universal%20fire%20security%20mathikhere&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
};
