


import './App.css';
import Home from './cmp/Home'
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './cmp/Header';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import React, { useEffect } from 'react';
import Services from './cmp/Services';
import About from './cmp/About';
import { Contact } from './cmp/Contact';
import Footer from './cmp/Footer';
import Gallery from './cmp/Gallery';
// import AOS from 'aos';
// import 'aos/dist/aos.css'; // 

function App() {
   
  //  useEffect(() => {
  //     AOS.init({
  //       duration: 800, // The duration of the animation in milliseconds
  //     });
  //   }, []);
  


  return (
     <>
    
     <BrowserRouter>
     <Header/>
        <Routes>
        <Route path='/' element={<Home />} />
         <Route path='/services' element={<Services />} />
         <Route path='/gallery' element={<Gallery />} />
         <Route path='/About' element={< About/>} />
        <Route path='/contact' element={<Contact />} />

        </Routes>
        <Footer/>
     
     
     </BrowserRouter>
     
     </>
  );
}

export default App;
