import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './Carousel.css'; // Import the CSS file

function ZoomingImage({ src, alt }) {
  return (
    <div className="zooming-image">
      <img src={src} alt={alt} />
    </div>
  );
}

function CarouselFadeExample() {
  return (
    <Carousel fade interval={4000}>
      <Carousel.Item>
        <ZoomingImage src='bg-1.jpg' alt='First slide' />
        <Carousel.Caption>
          <h3>24*7 Availability</h3>
          <p>We are available anytime for our customers</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <ZoomingImage src='bg-2.jpg' alt='Second slide' />
        <Carousel.Caption>
        <h3>24*7 Aailability</h3>
          <p>We are available anytime for our customers</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <ZoomingImage src='bg-3.jpg' alt='Third slide' />
        <Carousel.Caption>
        <h3>24*7 Aailability</h3>
          <p>We are available anytime for our customers</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselFadeExample;
