import React from "react";
import Carousel1 from "./carousel1";
import Header from "./Header";

const Services = () => {
  return (
    
    
    <div className="container py-5">
      <div className="text-center mb-5">
        <p>SERVICES</p>
        <h2 className="display-5 fw-bold mb-4">We Provide Best Fire Safety Services</h2>
        {/*<p className="fs-5">
          Nam ac neque sit amet velit gravida laoreet. Pellentesque nunc dolor,
          fermentum ut dictum eu, ornare quis leo. Suspendisse varius, eros non
          posuere egestas, ipsum augue congue nunc, sed gravida ante purus.
          Morbi efficitur tortor ultrices.
          </p> */}
      </div>
      <div>
        <Carousel1 />
      </div>
    </div>
    
  );
};

export default Services;
