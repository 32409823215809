import React from "react";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <div
        className="container-fluid py-3 pt-5"
        style={{ backgroundColor: "#1D262B", width: "100%" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-4 text-center mb-4">
              <img
                src="logo.png"
                className="mb-2 cmp-logo"
                style={{ width: "300px" }}
              />
              <div>
                <p
                  className="text-white mb-4 fs-5 "
                  style={{ textAlign: "justify" }}
                >
                  We offer quality, cost-effective solutions and pride ourselves
                  on our great customer satisfaction, which is why we are
                  totally committed to going the extra mile to achieve this.
                </p>
              </div>
              <h5 className="text-start text-white mb-5">
                Follows Us :
                <span
                  className="mx-2"
                  style={{
                    width: "70px",
                    height: "70px",
                    backgroundColor: "#3B5998",
                    padding: "10px 14px",
                    borderRadius: "50%",
                  }}
                >
                  <a
                    href="#"
                    className="text-decoration-none text-white"
                    target="_blank"
                  >
                    {" "}
                    <i className="fa fa-facebook card1"></i>
                  </a>
                </span>
                <span
                  className="mx-2"
                  style={{
                    width: "70px",
                    height: "70px",
                    backgroundColor: "#3B77B5",
                    padding: "10px 15px",
                    borderRadius: "50%",
                  }}
                >
                  <a
                    href="#"
                    className="text-decoration-none text-white"
                    target="_blank"
                  >
                    <i className="fa fa-linkedin card1"></i>
                  </a>
                </span>
                <span
                  className="mx-2"
                  style={{
                    width: "70px",
                    height: "70px",
                    backgroundColor: "#52A1F3",
                    padding: "10px 14px",
                    borderRadius: "50%",
                  }}
                >
                  <i className="fa fa-twitter card1"></i>
                </span>
              </h5>
            </div>

            {/* <div className='col-md-7'>

                        <div className='map'>
                    <iframe src="https://maps.google.com/maps?q=universal%20fire%20security%20mathikhere&t=&z=13&ie=UTF8&iwloc=&output=embed" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                </div>
                        </div> */}

            <div className="col-md-4 d-flex flex-column  align-items-center mb-4">
              <div>
                <h4
                  className="text-white mb-4 "
                  style={{ marginLeft: "-60px" }}
                >
                  <span
                    style={{
                      borderBottom: "3px solid #E85831",
                      paddingBottom: "15px",
                    }}
                  >
                    Pages
                  </span>
                </h4>
              </div>
              <div>
                <ul
                  style={{ listStyle: "none" }}
                  className="flex-column  align-items-center justify-content-center"
                >
                  <li className="py-2 fs-5">
                    <Link to="/" className="nav-link text-white text-start">
                      <i
                        className="fa fa-check me-2"
                        style={{ color: " #E85831" }}
                      ></i>
                      Home{" "}
                    </Link>
                  </li>
                  <li className="py-2 fs-5">
                    <Link
                      to="/About"
                      className="nav-link text-white text-start"
                    >
                      <i
                        className="fa fa-check me-2"
                        style={{ color: " #E85831" }}
                      ></i>
                      About Us{" "}
                    </Link>
                  </li>
                  <li className="py-2 fs-5">
                    <Link
                      to="/Services"
                      className="nav-link text-white text-start"
                    >
                      <i
                        className="fa fa-check me-2"
                        style={{ color: " #E85831" }}
                      ></i>
                      Products & Solutions{" "}
                    </Link>
                  </li>
                  <li className="py-2 fs-5">
                    <Link
                      to="/careers"
                      className="nav-link text-white text-start"
                    >
                      <i
                        className="fa fa-check me-2"
                        style={{ color: " #E85831" }}
                      ></i>
                      Careers{" "}
                    </Link>
                  </li>
                  <li className="py-2 fs-5">
                    <Link
                      to="/Contact"
                      className="nav-link text-white text-start"
                    >
                      <i
                        className="fa fa-check me-2"
                        style={{ color: " #E85831" }}
                      ></i>
                      Contact Us{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <h4 className="text-white mb-4 text-start ms-5 mb-5">
                <span
                  style={{
                    borderBottom: "3px solid #E85831",
                    paddingBottom: "15px",
                  }}
                >
                  Information
                </span>
              </h4>
              <div className="d-flex ms-2">
                <div>
                  <i
                    class="fa fa-map-marker  me-3"
                    style={{ fontSize: "90px ", color: "#E85831" }}
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="text-start pe-2">
                  <h5 className="text-white">Address</h5>
                  <p className="text-white fs-5">
                    {" "}
                    #10/14, 11th main road pranav complex,1st
                    floor,no.104,next2pai showroom, 1st Stage, Gokula 1st Stage,
                    Mathikere, Bengaluru, Karnataka 560054
                  </p>
                </div>
              </div>

              <div className="d-flex ms-2">
                <div>
                  <i
                    class="fa fa-envelope-o  me-3"
                    style={{ fontSize: "50px ", color: "#E85831" }}
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="text-start">
                  <h5 className="text-white">Email</h5>
                  <p style={{ color: "#fff" }} className="fs-5">
                  info@universalfiresecurity.com
                  </p>
                </div>
              </div>

              <div className="d-flex ms-2 mb-4">
                <div>
                  <i
                    class="fa fa-phone  me-3"
                    style={{ fontSize: "50px ", color: "#E85831" }}
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="text-start">
                  <h5 className="text-white">Mobile</h5>
                  <p style={{ color: "#fff" }} className="fs-5">
                    {" "}
                    +91 9036616910
                  </p>
                </div>
              </div>

              <div className="d-flex ms-2"></div>
            </div>
          </div>

          <hr className="text-white" />
          <div>
            <p className="text-center text-white fs-5">
              Copyright 2023 © All Right Reserved by{" "}
              <a href="#" className="text-white"></a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
