import React, { useEffect, useState } from "react";

import './Gallery.css';
import Modal from "react-modal";

import image001 from "../Assets/product1.png"
import image002 from "../Assets/product2.png"
import image003 from "../Assets/product3.png"



import image004 from "../Assets/product4.png"
import image005 from "../Assets/product5.png"
import image006 from "../Assets/product6.png"

import image007 from "../Assets/product7.png"
import image008 from "../Assets/product8.png"
import image009 from "../Assets/product9.png"

import image0010 from "../Assets/product10.png"
import image0012 from "../Assets/product11.png"
import image0011 from "../Assets/product12.png"
import image0013 from "../Assets/product13.png"
import image0014 from "../Assets/product14.png"

const Gallery = () => {
  
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
 
  const openModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalIsOpen(false);
  };
  
  const images1 = [image001, image002, image003];
  const images2 = [image004, image005, image006];
  const images3 = [image007, image008, image009];
  const images4 = [image0010, image0011, image0012];
  const images5 = [image0013, image0014];

  const imageTexts1 = ["Fire Alarm", "Fire Hydrant system", "CCTV"];
  const imageTexts2 = ["Access Control", "Biometric", "Refill of Extinguisher"];
  const imageTexts3 = ["Door Automation", "Video Door Phone", "Intrusion Alarm System – Wired and Wireless"];
  const imageTexts4 = ["Face + Card + Finger print Attendance System", "Finger print + RFID Attendance System", "Finger print only Attendance System"];
  const imageTexts5 = ["RFID card only based Attendance System", "Public address System"];

  return (
    <>
   
    <div className="md:pl-8 py-14 mt-20 md:mt-0 bg-blue-100">
      <div className="about-us mb-16">
        <h3 className="products1 font-bold text-slate-700 text-2xl mb-5 ml-5 md:ml-0 tracking-wider">
          Our Products
        </h3>
      </div>
       <div className="g-all">
       <span className="g-1" >
          {images1.map((image, index) => (
            <div key={index} className="image-container" style={{padding:'5px'}}  onClick={() => openModal(image)}>
              <img 
                 className="productss"
                src={image}
                alt={`Image ${index + 1}`}
                
              />
              <p    className="image-text" >{imageTexts1[index]}</p>
            </div>
          ))}
        </span>

        <span className="g-2" >
          {images2.map((image, index) => (
            <div key={index} className="image-container" style={{padding:'5px'}} onClick={() => openModal(image)}>
              <img 
                 className="productss"
                src={image}
                alt={`Image ${index + 1}`}
                
              />
              <p    className="image-text" >{imageTexts2[index]}</p>
            </div>
          ))}
        </span>

        

        

      

      <Modal 
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        contentLabel="Image Modal"
        style={{
          content: {
            width: '70%', // Adjust the percentage as needed
            margin: 'auto', // Center the modal horizontally
          }
        }}
       
      >
      
        {selectedImage && <img  
          style={{
            display: 'block',
            margin: '50px auto', // Center the image horizontally
            width: '100%', // Make the image fill the modal width
          }}  
         
        
          
          src={selectedImage} alt="Zoomed Image" />}
          <br/>
            <button  style={{width:'20%',marginLeft:'450px',borderRadius:'10px'}} onClick={closeModal}>Close</button>
      </Modal>

      
        
        
        
    </div>
    </div>
    </>
  );
};

export default Gallery;
