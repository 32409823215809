import React from "react";
import  "./About.css";
import image1 from '../../src/Assets/Fire1.jpg'

const About = () => {
  return (
    <div className="container mb-5">
      <div className="row">
        <div className="col-md-7" >
          <div className="about">
          <p >ABOUT UNIVERSAL FIRE & SECURITY SERVICES</p>
          </div>
          <h2 style={{fontSize:'40px'}} className="display-5 fw-bold mb-4">
            We Are Leader In Fire Safety and 
            <br />
            Security Services Providers
          </h2>
          <p style={{textAlign:'justify'}} className="fs-5">
                      Universal Fire and Security Services is a fully incorporated Indian Company with
            an objective to deliver quality solutions in Integrated Security Salutations Systems
            and Fire Alarm Systems. We are a corporate specializing in designing solutions in
            the field of Security System and Fire Detection. Universal provides quality
            products utilizing the latest technology with highly trained professional, wide
            range of industry experience and supreme design engineering and commissioning
            capability.

          </p>
          <p style={{textAlign:'justify'}} className="fs-5 mb-5">
                    We have strong team of professionals with a dedication to excellence, and in the
          delivery of installation and support services. We understand the needs of our
          customers. We employ advanced development tools to develop leading-edge
          products. We use the most effective and efficient project and service
          methodologies from the industry.
          </p>
          <div className="row">
            <div className="col-4">
              <img src="png2.png" style={{ width: "90px" }} className="mb-1" />
              <p className="fw-bold">Fire Suprresion</p>
            </div>
            <div className="col-4">
              <img src="png1.png" style={{ width: "80px" }} className="mb-3" />
              <p className="fw-bold">Fire Fighting</p>
            </div>
            <div className="col-4">
              <img src="png3.png" style={{ width: "80px" }} className="mb-3" />
              <p className="fw-bold">Rescue Service</p>
            </div>
          </div>
        </div>

        <div className="col-md-5">
          <img src={image1} style={{ width: "500px",height:'500px',borderRadius:'20px',marginTop:'100px' }} />
        </div>
      </div>
    </div>
  );
};

export default About;
