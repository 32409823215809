import React from "react";
import Header from "./Header";
import Carousel from "./Carousel";

import Counter from "./Counter";
import "./stylereact.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Footer from "./Footer";
import Services from "./Services";
import About from "./About";
const Home = () => {
  const responsiveOptions = {
    0: {
      items: 1, // Show 1 item on screens smaller than 600px
    },
    600: {
      items: 2, // Show 2 items on screens between 600px and 1000px
    },
    1000: {
      items: 3, // Show 3 items on screens larger than 1000px
    },
  };
  return (
    <>
      <Carousel />
      <div style={{ backgroundColor: "#f4f4f4" }} className="py-5">
        <About />

        <Services />

        <div className="bgimg">
          <div className="container">
            <div className="row py-5">
              <div className="col-md-3 py-5 ">
                <div className="cont-box text-white">
                  <i
                    className="	fa fa-check-square-o  mb-3"
                    style={{ fontSize: "80px" }}
                  ></i>
                  <h4 className="fs-1">
                    <Counter />
                  </h4>
                  <h4>Inspection Requests</h4>
                </div>
              </div>
              <div className="col-md-3 py-5 ">
                <div className="cont-box text-white">
                  <i
                    className="	fa 	fa fa-handshake-o mb-3"
                    style={{ fontSize: "80px" }}
                  ></i>
                  <h4 className="fs-1">95%</h4>
                  <h4>Public Meeting</h4>
                </div>
              </div>

              <div className="col-md-3 py-5 ">
                <div className="cont-box text-white">
                  <i
                    className="	fa fa fa-group  mb-3"
                    style={{ fontSize: "80px" }}
                  ></i>
                  <h4 className="fs-1">2300</h4>
                  <h4 className="text-center">Families and Property Saved</h4>
                </div>
              </div>

              <div className="col-md-3 py-5 ">
                <div className="cont-box text-white">
                  <i
                    className="	fa 	fa fa-bell-o  mb-3"
                    style={{ fontSize: "80px" }}
                  ></i>
                  <h4 className="fs-1">97%</h4>
                  <h4>Fire Alarm Inspection</h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container py-5">
          <div className="text-center mb-5">
            <h6>TESTIMONIALS</h6>
            <h2 className="display-5 fw-bold mb-4">
              What Clients Say About Us
            </h2>
            <p className="fs-5">
            We provide technical support across Karnataka. Technical issue will
            be addressed by our trained engineers to serve our customer in a
            speculated time frame.
            </p>
          </div>
          <OwlCarousel
            items={3}
            className="owl-theme"
            loop
            nav
            margin={20}
            autoplay
            autoplayTimeout={5000}
            responsive={responsiveOptions}
          >
            <div className=" mb-4">
              <div class="card border-0 shadow-lg">
                <div className="w-25 text-center rounded-circle mx-auto mt-3"></div>
                <div class="card-body text-center">
                  <i
                    className="fa fa-quote-left"
                    style={{ fontSize: "60px", color: "#E85831" }}
                  ></i>
                  <p class="card-text fs-5">
                    "Best decision for our business! They stood out with their responsiveness and cost-effective solutions. The tailored fire security system ensures the safety of our premises and employees. Highly recommended. ."
                  </p>
                  <span className="">
                    <i
                      className="fa fa-star me-2"
                      style={{ fontSize: "20px", color: "#f3c32a" }}
                    ></i>
                    <i
                      className="fa fa-star me-2"
                      style={{ fontSize: "20px", color: "#f3c32a" }}
                    ></i>
                    <i
                      className="fa fa-star me-2"
                      style={{ fontSize: "20px", color: "#f3c32a" }}
                    ></i>

                    <i
                      className="fa fa-star me-2"
                      style={{ fontSize: "20px", color: "#f3c32a" }}
                    ></i>
                    <i
                      className="fa fa-star me-2 mb-3"
                      style={{ fontSize: "20px", color: "#f3c32a" }}
                    ></i>
                  </span>

                  <h5 class="card-title text-black">Nita Agarwal</h5>
                </div>
              </div>
            </div>

            <div className=" mb-4">
              <div class="card border-0 shadow-lg">
                <div className="w-25 text-center rounded-circle mx-auto mt-3"></div>
                <div class="card-body text-center">
                  <i
                    className="fa fa-quote-left"
                    style={{ fontSize: "60px", color: "#E85831" }}
                  ></i>
                  <p class="card-text fs-5">
                    "Impressed with the expertise! They installed a comprehensive fire security system in my home, providing peace of mind for my family. The efficiency and thorough explanations during the process were much appreciated."
                  </p>
                  <span className="">
                    <i
                      className="fa fa-star me-2"
                      style={{ fontSize: "20px", color: "#f3c32a" }}
                    ></i>
                    <i
                      className="fa fa-star me-2"
                      style={{ fontSize: "20px", color: "#f3c32a" }}
                    ></i>
                    <i
                      className="fa fa-star me-2"
                      style={{ fontSize: "20px", color: "#f3c32a" }}
                    ></i>

                    <i
                      className="fa fa-star me-2"
                      style={{ fontSize: "20px", color: "#f3c32a" }}
                    ></i>
                    <i
                      className="fa fa-star me-2 mb-3"
                      style={{ fontSize: "20px", color: "#f3c32a" }}
                    ></i>
                  </span>

                  <h5 class="card-title text-black">Romika jain</h5>
                </div>
              </div>
            </div>

            <div className=" mb-4">
              <div class="card border-0 shadow-lg">
                <div className="w-25 text-center rounded-circle mx-auto mt-3"></div>
                <div class="card-body text-center">
                  <i
                    className="fa fa-quote-left"
                    style={{ fontSize: "60px", color: "#E85831" }}
                  ></i>
                  <p class="card-text fs-5">
                    "Impressed with the expertise! They installed a comprehensive fire security system in my home, providing peace of mind for my family. The efficiency and thorough explanations during the process were much appreciated."
                  </p>
                  <span className="">
                    <i
                      className="fa fa-star me-2"
                      style={{ fontSize: "20px", color: "#f3c32a" }}
                    ></i>
                    <i
                      className="fa fa-star me-2"
                      style={{ fontSize: "20px", color: "#f3c32a" }}
                    ></i>
                    <i
                      className="fa fa-star me-2"
                      style={{ fontSize: "20px", color: "#f3c32a" }}
                    ></i>

                    <i
                      className="fa fa-star me-2"
                      style={{ fontSize: "20px", color: "#f3c32a" }}
                    ></i>
                    <i
                      className="fa fa-star-half-full me-2 mb-3"
                      style={{ fontSize: "20px", color: "#f3c32a" }}
                    ></i>
                  </span>

                  <h5 class="card-title text-black">Rohit jaisawal</h5>
                </div>
              </div>
            </div>

           

            <div className=" mb-4">
              <div class="card border-0 shadow-lg">
                <div className="w-25 text-center rounded-circle mx-auto mt-3"></div>
                <div class="card-body text-center">
                  <i
                    className="fa fa-quote-left"
                    style={{ fontSize: "60px", color: "#E85831" }}
                  ></i>
                  <p class="card-text fs-5">
                    "Best decision for our business! They stood out with their responsiveness and cost-effective solutions. The tailored fire security system ensures the safety of our premises and employees. Highly recommended."
                  </p>
                  <span className="">
                    <i
                      className="fa fa-star me-2"
                      style={{ fontSize: "20px", color: "#f3c32a" }}
                    ></i>
                    <i
                      className="fa fa-star me-2"
                      style={{ fontSize: "20px", color: "#f3c32a" }}
                    ></i>
                    <i
                      className="fa fa-star me-2"
                      style={{ fontSize: "20px", color: "#f3c32a" }}
                    ></i>

                    <i
                      className="fa fa-star me-2"
                      style={{ fontSize: "20px", color: "#f3c32a" }}
                    ></i>
                    <i
                      className="fa fa-star-half-full me-2 mb-3"
                      style={{ fontSize: "20px", color: "#f3c32a" }}
                    ></i>
                  </span>

                  <h5 class="card-title text-black">Priya Roy</h5>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>

        <div></div>
      </div>
    </>
  );
};

export default Home;
