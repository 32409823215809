import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import './stylereact.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const Carousel1 = () => {
    const responsiveOptions = {
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 3,
        },
      };
  return (
    <div>

     <OwlCarousel
          items={3}
          className="owl-theme"
          loop
          nav
          margin={20}
          responsive={responsiveOptions}
        >
          <div className=" mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100 position-relative">
                <img src="service-1.jpg" style={{ height: "310px" }} />
                <div className="overlay">
                  <h5 className='px-4'>
                    <span className="fw-normal text-center">

                    Stored Pressure Powder fire extinguishers are ideal for use on Class A, 8 and C Fire Risks. fire extinguishers are available in 1kg, 2kg,4Kg, 6kg and 9kg standard versions
                    </span>
                  </h5>
                 
                </div>
              </div>

              <div class="card-body text-center">
                <h5>
                   <span className="fw-normal px-3">Stored Pressure, 1kg, 2kg, 6kg and 9kg</span>
                </h5>

               
              </div>
            </div>
          </div>

          <div className=" mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100   position-relative">
                <img src="service-4.jpg" style={{ height: "310px" }} />
                <div className="overlay">
                  <h5 className='px-4'>
                     <span className="fw-normal">
                        
                        Carbon Dioxide fire extinguishers are ideal for use on Class B Fire Risks.
                         carbon dioxide fire extinguishers are available in 2kg, 3kg, and 4 5kg standard Version
                        </span>
                  </h5>
                  
                </div>
              </div>
              <div class="card-body text-center">
                <h5>
                   <span className="fw-normal">
                   Carbon Dioxide Portable Type
                   </span>
                </h5>

                
              </div>
            </div>
          </div>

          <div className=" mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100  position-relative">
                <img src="service-5.jpg" style={{ height: "310px" }} />
                <div className="overlay">
                  <h5  className='px-4'>
                    <span className="fw-normal">
                    Water Fire Extinguishers are ideal for extinguishing Class A Fires.
                    </span>
                  </h5>
                  
                </div>
              </div>
              <div class="card-body text-center">
                <h5>
                   <span className="fw-normal">Stored Pressure & Cartridge type</span>
                </h5>

                
              </div>
            </div>
          </div>

          <div className=" mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100  position-relative">
                <img src="service-1.jpg" style={{ height: "310px" }} />
                <div className="overlay">
                  <h5  className='px-4'>
                    <span className="fw-normal">
                    Stored Pressure Powder fire extinguishers are ideal for use on Class A, 8 and C Fire Risks. fire extinguishers are available in 1kg, 2kg,4Kg, 6kg and 9kg standard versions
                    </span>
                  </h5>
                  
                </div>
              </div>
              <div class="card-body text-center">
                <h5>
                   <span className="fw-normal">
                   Stored Pressure, 1kg, 2kg, 6kg and 9kg
                   </span>
                </h5>

               
              </div>
            </div>
          </div>

          <div className=" mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100   position-relative">
                <img src="service-4.jpg" style={{ height: "310px" }} />
                <div className="overlay">
                  <h5>
                   <span className="fw-normal">
                   Carbon Dioxide fire extinguishers are ideal for use on Class B Fire Risks.
                         carbon dioxide fire extinguishers are available in 2kg, 3kg, and 4 5kg standard Version
                   </span>
                  </h5>
                 
                </div>
              </div>
              <div class="card-body text-center">
                <h5>
                 <span className="fw-normal">
                 Carbon Dioxide Portable Type
                 </span>
                </h5>

               
              </div>
            </div>
          </div>
        </OwlCarousel>

 {/* <OwlCarousel
          items={3}
          className="owl-theme"
          loop
          nav
          margin={20}
          responsive={responsiveOptions}
        >
          <div className=" mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100  mt-3 position-relative">
                <img src="com-1.jpg" style={{ height: "310px" }} />
                <div className="overlay">
                  <h5>
                    Title: <span className="fw-normal">3 BHK Apartment</span>
                  </h5>
                  <h5>
                    Location: <span className="fw-normal">NA</span>
                  </h5>
                </div>
              </div>
              <div class="card-body text-center">
                <h5>
                  Title : <span className="fw-normal">3 BHK Apartment</span>
                </h5>

                <h5>
                  Location : <span className="fw-normal">NA</span>
                </h5>
              </div>
            </div>
          </div>

          <div className=" mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100  mt-3 position-relative">
                <img src="com-2.jpg" style={{ height: "310px" }} />
                <div className="overlay">
                  <h5>
                    Title: <span className="fw-normal">2 BHK Apartment</span>
                  </h5>
                  <h5>
                    Location: <span className="fw-normal">NA</span>
                  </h5>
                </div>
              </div>
              <div class="card-body text-center">
                <h5>
                  Title : <span className="fw-normal">2 BHK Apartment</span>
                </h5>

                <h5>
                  Location : <span className="fw-normal">NA</span>
                </h5>
              </div>
            </div>
          </div>

          <div className=" mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100  mt-3 position-relative">
                <img src="com-3.jpg" style={{ height: "310px" }} />
                <div className="overlay">
                  <h5>
                    Title: <span className="fw-normal">4 BHK Apartment</span>
                  </h5>
                  <h5>
                    Location: <span className="fw-normal">NA</span>
                  </h5>
                </div>
              </div>
              <div class="card-body text-center">
                <h5>
                  Title : <span className="fw-normal">4 BHK Apartment</span>
                </h5>

                <h5>
                  Location : <span className="fw-normal">NA</span>
                </h5>
              </div>
            </div>
          </div>

          <div className=" mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100  mt-3 position-relative">
                <img src="com-4.jpg" style={{ height: "310px" }} />
                <div className="overlay">
                  <h5>
                    Title: <span className="fw-normal">Residential land</span>
                  </h5>
                  <h5>
                    Location: <span className="fw-normal">NA</span>
                  </h5>
                </div>
              </div>
              <div class="card-body text-center">
                <h5>
                  Title : <span className="fw-normal">Residential land</span>
                </h5>

                <h5>
                  Location : <span className="fw-normal">NA</span>
                </h5>
              </div>
            </div>
          </div>

          <div className=" mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100  mt-3 position-relative">
                <img src="plots1.jpg" style={{ height: "310px" }} />
                <div className="overlay">
                  <h5>
                    Title: <span className="fw-normal">Residential land</span>
                  </h5>
                  <h5>
                    Location: <span className="fw-normal">NA</span>
                  </h5>
                </div>
              </div>
              <div class="card-body text-center">
                <h5>
                  Title : <span className="fw-normal">Residential land</span>
                </h5>

                <h5>
                  Location : <span className="fw-normal">NA</span>
                </h5>
              </div>
            </div>
          </div>
        </OwlCarousel>
  */}
    </div>
  )
}

export default Carousel1