import React, { useEffect, useRef, useState } from 'react';
import CountUp from 'react-countup';
import { Element, scroller } from 'react-scroll';

const YourComponent = () => {
  const [isMounted, setIsMounted] = useState(false);
  const countUpRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      // Scroll to the element when it's in the viewport
      scroller.scrollTo('counter', {
        duration: 500, // Adjust the duration as needed
        smooth: true,
      });

      // Check if countUpRef is available before calling start
      if (countUpRef.current) {
        // Start the count-up animation
        countUpRef.current.start();
      }

      // Remove the scroll event listener once the animation has started
      window.removeEventListener('scroll', handleScroll);
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMounted]);

  // Set isMounted to true once the component is mounted
  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <div>
      <Element name="counter" className="element">
        <div>
          {isMounted && (
            <CountUp start={0} end={4500} ref={countUpRef} />
          )}
        </div>
        
      </Element>

      
    </div>
  );
};

export default YourComponent;
